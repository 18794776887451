exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-current-events-js": () => import("./../../../src/pages/current-events.js" /* webpackChunkName: "component---src-pages-current-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-fields-slug-js": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-js" */),
  "component---src-pages-old-events-js": () => import("./../../../src/pages/old-events.js" /* webpackChunkName: "component---src-pages-old-events-js" */),
  "component---src-pages-project-single-js": () => import("./../../../src/pages/project-single.js" /* webpackChunkName: "component---src-pages-project-single-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-upcoming-events-js": () => import("./../../../src/pages/upcoming-events.js" /* webpackChunkName: "component---src-pages-upcoming-events-js" */),
  "component---src-pages-upcoming-projects-js": () => import("./../../../src/pages/upcoming-projects.js" /* webpackChunkName: "component---src-pages-upcoming-projects-js" */)
}

